
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {Team} from "@/models/team.models";
import TeamRepository from "@/services/TeamRepository";
import moment from "moment";
import authRepository from "@/services/AuthRepository";
import {UserDetail} from "@/models/userDetail";

export default {
  setup() {
    const toast = useToast();
    const isLoading = ref(false);
    const list = ref([] as UserDetail[]);
    const store = useStore();

    if(!(store.state.permission == "admin")){
      router.push({
        name: 'home'
      });
    }


    authRepository.getListUser()
        .then((response) => {
          list.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:'Lỗi khi tải danh sách công dân',
            life: 2000
          });
        });

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };
    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };
    const permissionName = (st) => {
      if(st == "master") return "Quản lý tòa nhà";
      else return "Nhân viên công ty";
    }

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      permissionName,
    }
  }
}
