

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {GroupCreate} from "@/models/groupCreate";
import teamRepository from "../services/TeamRepository";
import {Group} from "@/models/group";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {MemberInGroup} from "@/models/memberInGroup";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const groupCreate = ref({} as GroupCreate);
    const listGroup = ref([] as Group[]);
    const listCustomer = ref([] as ListCustomer[]);
    const idGroup = ref(0);
    const idCustomer = ref(0);
    const idGroupDelete = ref(0);
    const idMemberDelete = ref(0);
    const memberInGroup = ref([] as MemberInGroup[]);

    const loadListGroup = () => {
      zaloRepository.listGroup()
          .then((response) => {
            listGroup.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    loadListGroup();

    const loadListMember = () => {
      zaloRepository.getListCustomer()
          .then((response) => {
            listCustomer.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }

    loadListMember();

    const addGroup = () => {
      zaloRepository.createNewGroup(groupCreate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm nhóm mới thành công',
              life: 2000
            });
            loadListGroup();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const addMember = () => {
      zaloRepository.addMemberToGroup(idCustomer.value, idGroup.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            loadListGroup();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const selectGroup = () => {
      console.log("################### selectGroup idGroupDelete: " + idGroupDelete.value);
      if(idGroupDelete.value > 0)
        zaloRepository.getListMemberInGroup(idGroupDelete.value)
          .then((response) => {
            memberInGroup.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteMember = () => {
      zaloRepository.deleteMember(idMemberDelete.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa thành viên ra khỏi nhóm thành công',
              life: 2000
            });
            selectGroup();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    return {
      register,
      error,
      addGroup,
      groupCreate,
      listCustomer,
      listGroup,
      idGroup,
      idCustomer,
      addMember,
      idGroupDelete,
      idMemberDelete,
      selectGroup,
      memberInGroup,
      deleteMember,
    }
  }
}

