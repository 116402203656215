
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {Team} from "@/models/team.models";
import TeamRepository from "@/services/TeamRepository";
import moment from "moment";
import authRepository from "@/services/AuthRepository";
import {UserDetail} from "@/models/userDetail";
import {CustomerUpdate} from "@/models/customerUpdate";
import zaloRepository from "@/services/ZaloRepository";
import {ListGroup} from "@/models/listGroup";

export default {
  setup() {
    const toast = useToast();
    const isLoading = ref(false);
    const list = ref([] as CustomerUpdate[]);
    const listTemp = ref([] as CustomerUpdate[]);
    const store = useStore();
    const adminPermission = ref(false);
    if(store.state.permission == "admin")
    {
      adminPermission.value = true;
    }

    // if(!(store.state.permission == "admin")){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    const listCivilGroup = ref([] as ListGroup[]);
    const civilGroupSelect = ref({} as ListGroup);

    console.log("@@@@@@@@@@@@@@@@@@@ selectCivilGroup store.state.permission: " + store.state.permission);

    zaloRepository.getListCustomerDetail()
        .then((response) => {
          listTemp.value = list.value = response.data;
          const civilGroupAll = ref({} as ListGroup);
          civilGroupAll.value.name = "Tất cả";
          listCivilGroup.value.push(civilGroupAll.value);
          list.value.forEach(x => {
            const check = ref(false);
            listCivilGroup.value.forEach(y =>{
              if(y.name == x.civilGroupName) check.value = true;
            });
            if(!check.value && x.civilGroupName != null)
            {
              const civilGroup = ref({} as ListGroup);
              civilGroup.value.name = x.civilGroupName;
              listCivilGroup.value.push(civilGroup.value);
            }
          })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:'Lỗi khi tải danh sách công dân',
            life: 2000
          });
        });

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };
    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };
    const permissionName = (st) => {
      if(st == "master") return "Quản lý tòa nhà";
      else return "Nhân viên công ty";
    }

    const selectCivilGroup = () => {
      console.log("@@@@@@@@@@@@@@@@@@@ selectCivilGroup civilGroupSelect: " + JSON.stringify(civilGroupSelect.value));
      console.log("@@@@@@@@@@@@@@@@@@@ selectCivilGroup civilGroupSelect name: " + civilGroupSelect.value.name);
      if(civilGroupSelect.value.name == "Tất cả")
      {
        listTemp.value = list.value;
      }
      else {
        listTemp.value = list.value.filter(x => x.civilGroupName == civilGroupSelect.value.name);
      }
      console.log("@@@@@@@@@@@@@@@@@@@ selectCivilGroup civilGroupSelect listTemp: " + JSON.stringify(listTemp.value));
    }

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      permissionName,
      listCivilGroup,
      selectCivilGroup,
      civilGroupSelect,
      listTemp,
      adminPermission
    }
  }
}
