

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import moment from "moment";
import {UserUpdate} from "@/models/userUpdate";
import Ward from "@/models/Ward.models";
import CivilGroup from "@/models/CivilGroup.models";
import zaloRepository from "@/services/ZaloRepository";
import router from "@/router";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();
    const userUpdate = ref({} as UserUpdate);
    const toast = useToast();
    const store = useStore();
    const password = ref("");
    const userPermission = ref([
      { label: 'Tài khoản cấp tổ', value: 'civilGroup' },
      { label: 'Tài khoản cấp phường', value: 'ward' },
      // { label: 'Tài khoản cấp quận', value: 'district' },
      { label: 'Quản lý', value: 'admin' },
    ]);
    const ward = ref({} as Ward);
    const civilGroup = ref({} as CivilGroup);
    const listWard = ref([] as Ward[]);
    const listCivilGroup = ref([] as CivilGroup[]);
    if(!(store.state.permission == "admin")){
      router.push({
        name: 'home'
      });
    }

    const valid = computed(()=> {
      console.log("$$$$$$$############### debug permission: " + userUpdate.value.permission);
      if(userUpdate.value.permission == "civilGroup")
      {
        return userUpdate.value.fullname&&userUpdate.value.username&&civilGroup.value.wardCode;
      }
      else if(userUpdate.value.permission == "ward")
      {
        return userUpdate.value.fullname&&userUpdate.value.username;
      }
      else if((userUpdate.value.permission == "district")||(userUpdate.value.permission == "admin")) {
        return userUpdate.value.fullname&&userUpdate.value.username;
      }
    });

    zaloRepository.getListWard()
        .then((response) => {
          listWard.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    AuthRepository.getUserById(props.id)
        .then((response) => {
          userUpdate.value = response.data;
          userUpdate.value.password = "";
          if(userUpdate.value.idWard != null) {
              listWard.value.forEach(x => {
                if(x.id == userUpdate.value.idWard) {
                  ward.value = x;
                  if(userUpdate.value.idCivilGroup != null)
                  {
                    zaloRepository.getListCivilGroup()
                        .then((response) => {
                          listCivilGroup.value = response.data;
                          listCivilGroup.value.forEach(x => {
                            if(x.id == userUpdate.value.idCivilGroup) civilGroup.value = x;
                          });
                        })
                        .catch(err => {
                          toast.add({
                            severity: 'error',
                            summary: 'Lỗi',
                            detail:err.response.data,
                            life: 2000
                          });
                        });
                  }
                }
              })
          }
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    const doUpdate = () => {
      if(password.value.trim().length < 6 && password.value.trim().length > 0)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else
      {
        const temp = ref({} as UserUpdate);
        userUpdate.value.password = "";
        userUpdate.value.idWard = temp.value.idWard;
        userUpdate.value.idCivilGroup = temp.value.idCivilGroup;

        console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 civilGroup: " + JSON.stringify(civilGroup.value));
        console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 ward: " + JSON.stringify(ward.value));

        if(password.value.trim().length == 0)
        {
          userUpdate.value.password = "";
        }
        else
        {
          var md5 = require("md5");
          userUpdate.value.password = md5(password.value.trim());
        }
        if(ward.value != null)
        {
          userUpdate.value.idWard = ward.value.id;
        }
        if(civilGroup.value != null)
        {
          userUpdate.value.idWard = ward.value.id;
          userUpdate.value.idCivilGroup = civilGroup.value.id;
        }
        AuthRepository.updateUser(userUpdate.value)
                .then((response) => {
                  console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222: " + JSON.stringify(userUpdate.value));
                  toast.add({
                    severity: 'success',
                    summary: 'Cập nhật',
                    detail: 'Cập nhật thông tin tài khoản thành công',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    };
    const confirm = useConfirm();
    const del = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá tài khoản này không?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          AuthRepository.deleteUser(userUpdate.value.id)
              .then(response => {
                toast.add({severity:'info', summary:'Confirmed', detail:'Tài khoản đã được xoá thành công', life: 3000});
                router.push({
                  name: 'userList'
                });
              })
              .finally(()=>{
              });
        },
        reject: () => {
        }
      });
    };

    const selectPermission = () => {
      listWard.value = [];
      //listCivilGroup.value = [];
      const wardtemp = ref({} as Ward);
      const civilGrouptemp = ref({} as CivilGroup);
      ward.value = wardtemp.value;
      civilGroup.value = civilGrouptemp.value;
      if((userUpdate.value.permission == "civilGroup")||(userUpdate.value.permission == "ward"))
      {
        zaloRepository.getListWard()
            .then((response) => {
              listWard.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };
    const selectWard = () => {
      listCivilGroup.value = [];
      // if((userUpdate.value.permission == "civilGroup"))
      {
        zaloRepository.getListCivilGroup()
            .then((response) => {
              listCivilGroup.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };

    selectWard();
    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };

    return {
      userUpdate,
      doUpdate,
      del,
      formatDateTime,
      userPermission,
      password,
      listWard,
      listCivilGroup,
      ward,
      civilGroup,
      selectPermission,
      selectWard,
      valid,
    }
  }
}

