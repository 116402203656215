

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import Ward from "@/models/Ward.models";
import CivilGroup from "@/models/CivilGroup.models";
import zaloRepository from "@/services/ZaloRepository";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listWard = ref([] as Ward[]);
    const listCivilGroup = ref([] as CivilGroup[]);
    const ward = ref({} as Ward);
    const civilGroup = ref({} as CivilGroup);

    const valid = computed(()=> {
      console.log("$$$$$$$############### debug permission: " + register.value.permission);
      if(register.value.permission == "civilGroup")
      {
        return register.value.fullname&&register.value.username&&register.value.password&&civilGroup.value.wardCode;
      }
      else if(register.value.permission == "ward")
      {
        return register.value.fullname&&register.value.username&&register.value.password;
      }
      else if((register.value.permission == "district")||(register.value.permission == "admin")) {
        return register.value.fullname&&register.value.username&&register.value.password;
      }
    });

    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }
    const teams = ref({} as Team[]);

    register.value.permission = '';
    const userPermission = ref([
          { label: 'Tài khoản cấp tổ', value: 'civilGroup' },
          { label: 'Tài khoản cấp phường', value: 'ward' },
          // { label: 'Tài khoản cấp quận', value: 'district' },
          { label: 'Quản lý', value: 'admin' },
      ]);


    TeamRepository.getTeams()
            .then((response) => {
              teams.value = response.data;
            })
            .catch();

    const doRegister = () => {
      if(register.value.password.length < 6)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        if(ward.value != null)
        {
          register.value.idWard = ward.value.id;
        }
        if(civilGroup.value != null)
        {
          register.value.idWard = ward.value.id;
          register.value.idCivilGroup = civilGroup.value.id;
        }
        AuthRepository.registerUser(register.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    }

    // const selectPermission = () => {
    //   listWard.value = [];
    //   listCivilGroup.value = [];
    //   if((register.value.permission == "civilGroup")||(register.value.permission == "ward"))
    //   {
    //     zaloRepository.getListWard()
    //         .then((response) => {
    //           listWard.value = response.data;
    //         })
    //         .catch(err => {
    //           toast.add({
    //             severity: 'error',
    //             summary: 'Lỗi',
    //             detail:err.response.data,
    //             life: 2000
    //           });
    //         });
    //   }
    // };
    const selectWard = () => {
      listCivilGroup.value = [];
      // if((register.value.permission == "civilGroup"))
      {
        zaloRepository.getListCivilGroup()
            .then((response) => {
              listCivilGroup.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };
    selectWard();

    return {
      register,
      doRegister,
      valid,
      teams,
      userPermission,
      error,
      // selectPermission,
      civilGroup,
      ward,
      listCivilGroup,
      listWard,
      selectWard,
    }
  }
}

