

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import moment from "moment";
import {UserUpdate} from "@/models/userUpdate";
import Ward from "@/models/Ward.models";
import CivilGroup from "@/models/CivilGroup.models";
import zaloRepository from "@/services/ZaloRepository";
import router from "@/router";
import {CustomerUpdate} from "@/models/customerUpdate";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();
    const userUpdate = ref({} as UserUpdate);
    const toast = useToast();
    const store = useStore();
    const listCivilGroup = ref([] as CivilGroup[]);
    const genderList = ref([
      { label: 'nam', value: 'nam' },
      { label: 'nữ', value: 'nữ' },
    ]);
    // if(!(store.state.permission == "admin")){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    const customer = ref({} as CustomerUpdate);

    // const valid = computed(()=> {
    //   console.log("$$$$$$$############### debug permission: " + userUpdate.value.permission);
    //   if(userUpdate.value.permission == "civilGroup")
    //   {
    //     return userUpdate.value.fullname&&userUpdate.value.username&&civilGroup.value.wardCode;
    //   }
    //   else if(userUpdate.value.permission == "ward")
    //   {
    //     return userUpdate.value.fullname&&userUpdate.value.username;
    //   }
    //   else if((userUpdate.value.permission == "district")||(userUpdate.value.permission == "admin")) {
    //     return userUpdate.value.fullname&&userUpdate.value.username;
    //   }
    // });

    const doUpdate = () => {
      zaloRepository.updateCustomerByUser(customer.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Cập nhật dân cư thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    zaloRepository.getCustomerById(props.id)
        .then((response) => {
          customer.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    zaloRepository.getListCivilGroup()
        .then((response) => {
          listCivilGroup.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    return {
      userUpdate,
      customer,
      genderList,
      listCivilGroup,
      doUpdate,
    }
  }
}

