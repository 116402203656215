

  import { computed, ref } from 'vue'
  import {useToast} from "primevue/usetoast";
  import axios from "axios";
  import {useStore} from "vuex";
  import {useRouter} from "vue-router";
  import {FileDetail} from "@/models/fileDetail.models";
  import ZaloRepository from "@/services/ZaloRepository";
  import Ward from "@/models/Ward.models";
  import zaloRepository from "@/services/ZaloRepository";
  import authRepository from "@/services/AuthRepository";
  import {ImportTax} from "@/models/importTax";
  import {TaxDetail} from "@/models/taxDetail";
  import {FilterMatchMode} from "primevue/api";
  import moment from "moment";
  import {TaxSearch} from "@/models/taxSearch";
  import {ResDetail} from "@/models/resDetail";

  export default {
    setup() {
      const layout = ref('grid');
      const toast = useToast();
      const show = ref(false);
      const store = useStore();
      const router = useRouter();
      const fileName = ref("");
      const idEvent = ref(0);
      const minDate = ref(new Date());
      const listWard = ref([] as Ward[]);
      const ward = ref({} as Ward);
      const importTax = ref({} as ImportTax);
      const index = ref(0);
      const listTax = ref([] as TaxDetail[]);
      const listWardFilter = ref([]);
      const date = ref();
      const taxSearch = ref({} as TaxSearch);
      const downloadFile = ref(false);
      const adminPermission = ref(false);
      const sendMessage = ref(false);
      const resDetail = ref({} as ResDetail);
      const res = ref("");

      if(!(!!store.state.token)){
        router.push({
          name: 'login'
        });
      }

      if(store.state.permission == 'civilGroup'){
        router.push({
          name: 'login'
        });
      }
      if((store.state.permission == 'admin')||(store.state.permission == 'district')){
        adminPermission.value = true;
      }

      console.log("######## debug tax importTax: " + JSON.stringify(importTax.value));
      zaloRepository.getListWard()
          .then((response) => {
            listWard.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });

      // authRepository.getListTax()
      //     .then((response) => {
      //       listTax.value = response.data;
      //     })
      //     .catch(err => {
      //       toast.add({
      //         severity: 'error',
      //         summary: 'Lỗi',
      //         detail:err.response.data,
      //         life: 2000
      //       });
      //     });

      let listFileName = ref([] as FileDetail[]);
      const loadingBar = ref(false);
      const loadingBarProcess = ref(false);
      const loadingExport = ref(false);
      const fileChange = (fileList) => {
        fileName.value = fileList[0].name;
        listFileName.value = [];
        var leng = fileList.length;
        for (var i = 0; i < leng; i++)
        {
          var filetemp = ref({} as FileDetail);
          filetemp.value.nameFile = fileList[i].name;
          filetemp.value.value = fileList[i];
          listFileName.value.push(filetemp.value);
        }
      };
      const upload = () => {
        {
          var files = new FormData();
          listFileName.value.forEach(x =>
          {
            files.append("formFiles", x.value, x.nameFile);
          });
          loadingBar.value = true;
          axios.post(`/api/File/UploadFileTax`, files,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                importTax.value.link = response.data;
                  loadingBar.value = false;
                  listFileName.value = [];
                  show.value = true;
                  toast.add({
                    severity: 'success',
                    summary: 'Thành công',
                    detail:'Tải danh sách lên thành công',
                    life: 2000
                  });
          })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })});
        }
      };
      const createList = () => {
        loadingBarProcess.value = true;
        //importTax.value.idWard = index.value;
        importTax.value.time = date.value;
        console.log("######## debug tax createList importTax: " + JSON.stringify(importTax.value));
        authRepository.createListTax(importTax.value)
            .then((response) => {
              resDetail.value = response.data;
              loadingBarProcess.value = false;
              if(resDetail.value.success){
                res.value = "Đăng tải danh sách:" + "\n" + "Thành công: " + resDetail.value.successNumber + " hồ sơ thuế"
                    + "\n" + ", Thất bại: " + resDetail.value.failNumber + " hồ sơ thuế";
              }
              else{
                res.value = "Đăng tải danh sách không thành công";
              }
              toast.add({
                severity: 'success',
                summary: 'Thông báo',
                detail: 'Tạo danh sách công dân thành công',
                life: 4000
              });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 4000
              });
              loadingBarProcess.value = false;
            });
      };
      const downLoadFile = () => {
        axios({
          url: '../api/File/downloadtaxtemplate/',
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download',"dsthue.xlsx");
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };
      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      });

      const clearFilter = () => {
        initFilters();
      };

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const formatDateTime = (date) => {
        return moment(String(date)).format('YYYY');
      };

      const resultStatus = (status) => {
        if(status) return "Đã nộp thuế";
        else return "Chưa nộp thuế";
      }

      const moneyValue = (x) => {
        return Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(x);
      }

      const loadListTax = () => {
        listTax.value = [];
        authRepository.getListTaxWithYear(taxSearch.value)
            .then((response) => {
              listTax.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }

      const exportFile = () => {
        loadingExport.value = true;
        authRepository.exportListTax(listTax.value)
            .then((response) => {
              fileName.value = response.data;
              toast.add({
                severity: 'success',
                summary: 'Xuất dữ liệu',
                detail: 'Xuất dữ liệu thành công',
                life: 2000
              });
              loadingExport.value = false;
              downloadFile.value = true;
              // router.push({
              //   name: 'home'
              // });
            })
            .catch(err => {
              loadingBar.value = false;
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }

      const downLoadFileExport = () => {
        axios({
          url: '../api/auth/downloadListTax/' + fileName.value,
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download',"DanhSachThueDat.xlsx");
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };

      const resendMessageNotification = () => {
        sendMessage.value = true;
        ZaloRepository.resendMessageTax(listTax.value)
            .then((response) => {
              sendMessage.value = false;
              toast.add({
                severity: 'success',
                summary: 'Gởi thông báo',
                detail: 'Gởi thông báo thuế thành công',
                life: 2000
              });
            })
            .catch(err => {
              sendMessage.value = false;
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }

      return {
        layout,
        upload,
        loadingBar,
        listFileName,
        fileChange,
        show,
        fileName,
        loadingBarProcess,
        createList,
        downLoadFile,
        idEvent,
        minDate,
        listWard,
        ward,
        importTax,
        index,
        listTax,
        filters,
        clearFilter,
        initFilters,
        resultStatus,
        moneyValue,
        listWardFilter,
        date,
        formatDateTime,
        taxSearch,
        loadListTax,
        downloadFile,
        exportFile,
        downLoadFileExport,
        loadingExport,
        adminPermission,
        sendMessage,
        resendMessageNotification,
        res,
        resDetail
      }
    }

  }
